<template>
  <b-card-code>
    <div v-if="$can('create','Employee Loan')" class="custom-search d-flex">
      <b-button variant="outline-primary" @click="showModal">
        {{ $t('Add New') }}
      </b-button>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <b-col md="3">
        <b-card-text>
          <span>Select Department </span>
        </b-card-text>
        <validation-provider
            #default="{ errors }"
            name="department"
            rules=""
        >
          <select
              name="department_id"
              v-model="department_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control"
          >
            <option :value="null">Select One</option>
            <option
                v-for="(department, key) in departments"
                :value="department.id"
                :key="key"
            >
              {{ department.name }}
            </option>
          </select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <b-card-text>
          <span>Select Designations</span>
        </b-card-text>
        <validation-provider
            #default="{ errors }"
            name="designation"
            rules=""
        >
          <select
              name="designation_id"
              v-model="designation_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control"
          >
            <option :value="null">Select One</option>
            <option
                v-for="(designation, key) in designations"
                :value="designation.id"
                :key="key"
            >
              {{ designation.name }}
            </option>
          </select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Search')"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="employee_loans"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex + 1 }}
        </span>
        <span v-else-if="props.column.field === 'image'">
          <template>
            <img :src="props.row.image?imgShow(props.row.image):'/student_face.gif'" width="50" height="50" alt="">
          </template>
        </span>
        <span v-else-if="props.column.field === 'employee_id'">
          {{getEmployeeId(props.row.employee_id) }}
        </span>
        <span v-else-if="props.column.field === 'name'">
          {{employeeNameById(props.row.employee_id) }}
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- modal login-->
    <b-modal ref="my-modal" hide-footer size="lg" :title="model_mode==='add'?$t('Add New Loan'):$t('Information Edit')">
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form method="post" ref="add_or_update_form" @submit="validationForm" action="javascript:void(0)">
          <b-row>

            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Employee ID') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('employee id')"
                  rules="required"
              >
                <b-form-input name="employee_id" v-model="employee_id"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write employee id')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-button class="mt-2" type="button" @click="findEmployee()">Find</b-button>
            </b-col>
            <b-col md="12"></b-col>
            <template v-if="employee.id" class="mt-2">
              <b-col class="mb-4 mt-2" md="6">
                <table>
                  <tr>
                    <td>Employee ID</td>
                    <td>: {{ employee.institute_emp_id }}</td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>: {{ employee.userable ? employee.userable.name : '' }}</td>
                  </tr>
                  <tr>
                    <td>Father Name</td>
                    <td>: {{ employee.father_name }}</td>
                  </tr>
                  <tr>
                    <td>Mother Name</td>
                    <td>: {{ employee.mother_name }}</td>
                  </tr>
                  <tr>
                    <td>Version</td>
                    <td>: {{ versionByID(employee.version_id) }}</td>
                  </tr>
                  <tr>
                    <td>Shift</td>
                    <td>: {{ shiftByID(employee.shift_id) }}</td>
                  </tr>
                  <tr>
                    <td>Employee Designation</td>
                    <td>: {{ designationByID(employee.designation_id) }}</td>
                  </tr>
                </table>
              </b-col>
              <b-col class="mb-4 mt-2" md="6">
                <table>
                  <tr>
                    <td>Employee Scale</td>
                    <td>: {{ scaleByID(employee.scale_id) }}</td>
                  </tr>
                  <tr>
                    <td>Employee Grade</td>
                    <td>: {{ designationScaleByID(employee.designation_scale_id) }}</td>
                  </tr>
                  <tr>
                    <td>Basic salary</td>
                    <td>: {{ designationScaleAmountByID(employee.designation_scale_id) }}</td>
                  </tr>
                  <tr>
                    <td>Provident Fund</td>
                    <td>: {{ amount.fund }}</td>
                  </tr>
                  <tr>
                    <td>Previous Loan</td>
                    <td>: {{ amount.loan }}</td>
                  </tr>
                  <tr>
                    <td>Payable Loan</td>
                    <td style="color: red">: {{ amount.payable_loan }}</td>
                  </tr>
                  <tr>
                    <td>Paid Loan</td>
                    <td style="color: green">: {{ amount.paid_loan }}</td>
                  </tr>
                </table>
              </b-col>
              <b-col md="4">
                <b-card-text>
                  <span>{{ $t('Loan amount') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('loan amount')"
                    rules="required"
                >
                  <b-form-input name="loan_amount" v-model="loan_amount" type="number"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :placeholder="$t('loan amount')"
                                class="form-control">
                  </b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <b-card-text>
                  <span>{{ $t('Number of installment') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('number of installment')"
                    rules="required"
                >
                  <b-form-input name="issue_installment" v-model="issue_installment" type="number"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :placeholder="$t('loan amount')"
                                class="form-control">
                  </b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <b-card-text>
                  <span>{{ $t('Loan month') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('loan month')"
                    rules="required"
                >
                  <b-form-input name="month_year" v-model="month_year" type="month"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :placeholder="$t('mm/yyyy')"
                                class="form-control">
                  </b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <!-- submit button -->
              <b-col>
                <b-button variant="primary" type="submit">Submit</b-button>
              </b-col>
            </template>
          </b-row>

        </form>
      </validation-observer>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton, BPagination, BFormGroup, BFormInput, BFormSelect, BCardText,
  BRow, BCol,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  required, integer, email,
} from '@validations'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);

export default {
  name: 'EmployeeLoan',
  components: {
    BCardCode, ValidationProvider, ValidationObserver,
    VueGoodTable, BCardText, BRow, BCol,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required, integer, email,
      department_id:null,
      designation_id:null,
      pageLength: 10,
      dir: false,
      model_mode: 'add',
      employee_id: '',
      loan_amount: 0,
      payable_amount: 0,
      issue_installment: 0,
      payable_installment: 0,
      month_year: '',
      selected_row: {},
      columns: [
        {
          label: this.$t('SL'),
          field: 'id',
        },
        {
          label: this.$t('Photo'),
          field: 'image',
        },
        {
          label: this.$t('Employee ID'),
          field: 'employee_id',
        },
        {
          label: this.$t('Name'),
          field: 'name',
        },
        {
          label: this.$t('Loan month'),
          field: 'month_year',
        },
        {
          label: this.$t('Installment'),
          field: 'issue_installment',
        },
        {
          label: this.$t('Payable installment'),
          field: 'payable_installment',
        },
        {
          label: this.$t('Loan amount'),
          field: 'loan_amount',
        },
        {
          label: this.$t('Payable amount'),
          field: 'payable_amount',
        },
      ],
      searchTerm: '',
      status: {
        1: this.$t('active'),
        0: this.$t('deactivate'),
      },
      employee: {},
      amount: {},
      columns10: [
        {
          label: 'Employee ID',
          field: 'EmployeeId',
        },
        {
          label: 'Name',
          field: 'Name',
        },
        {
          label: 'Loan Month',
          field: 'LoanMonth',
        },
        {
          label: 'Installment',
          field: 'Installment',
        },
        {
          label: 'Payable Installment',
          field: 'PayableInstallment',
        },
        {
          label: 'Loan Amount',
          field: 'LoanAmount',
        },
        {
          label: 'Payable Amount',
          field: 'PayableAmount',
        },
      ],
      filter_ids:[],
    }
  },
  methods: {
    selectedRow(row) {
      this.model_mode = 'edit';
      this.selected_row = row;
      this.$refs['my-modal'].show()
    },
    imgShow(path) {
      return process.env.VUE_APP_ENV_RESOURCHURL + path;
    },
    statusChange(id, index) {
      this.$swal({
        title: 'Are you sure?',
        text: "You want to change status!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/employee/status/change${id}`).then((response) => {
            this.$toaster.success(response.data.message);
            this.$store.commit('STATUS_CHANGE_EMPLOYEE', index);
          }).catch((error) => {
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    showModal() {
      this.model_mode = 'add';
      this.selected_row = {};
      this.employee= {};
      this.amount= {};
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.loan_amount>(this.amount.fund -(this.amount.payable_loan-this.amount.paid_loan))){
            this.$toaster.error('Loan amount is not more then provident fund amount');
          }else{
            let data = new FormData(this.$refs.add_or_update_form);
            if (this.model_mode === 'add') {
              apiCall.post(`/employee/loan/store${this.employee.id}`, data).then((response) => {
                if (response.data.status === 'success') {
                  this.$toaster.success(response.data.message);
                  this.hideModal();
                  this.$store.commit('ADD_NEW_EMPLOYEE_LOAN',response.data.data);
                  this.$refs.simpleRules.reset();
                } else {
                  this.$toaster.error(response.data.message);
                }
              }).catch((error) => {
                if (error.response.status == 422) {
                  Object.keys(error.response.data.errors).map((field) => {
                    this.$toaster.error(error.response.data.errors[field][0]);
                  })
                } else this.$toaster.error(error.response.data.message);
              });
            } else {
              apiCall.post(`/employee/update${this.selected_row.id}`, data).then((response) => {
                if (response.data.status === 'success') {
                  this.$toaster.success(response.data.message);
                  this.hideModal();
                  this.$store.dispatch('GET_ALL_EMPLOYEE');
                  this.$refs.simpleRules.reset();
                } else {
                  this.$toaster.error(response.data.message);
                }
              }).catch((error) => {
                if (error.response.status == 422) {
                  Object.keys(error.response.data.errors).map((field) => {
                    this.$toaster.error(error.response.data.errors[field][0]);
                  })
                } else this.$toaster.error(error.response.data.message);
              });
            }
          }
        }
      })
    },
    findEmployee() {
      if (!this.employee_id){
        this.$toaster.error('Please write employee id first');
      } 
      else {
        apiCall.get(`/employee/find/byInstituteEmpId${this.employee_id}`).then((response) => {
          if (response.data.status === 'success') {
            this.employee = response.data.employee;
            this.amount = response.data.amount;
          } else {
            this.employee = {};
            this.$toaster.error(response.data.message);
          }
        }).catch((error) => {
          this.employee = {};
          this.$toaster.error(error);
        })
      }
    },
    pdfGenerate() {
      let title = "Loans";
      let clm = ['EmployeeId','Name','LoanMonth','Installment','PayableInstallment','LoanAmount','PayableAmount', 'getEmployeeId' ];
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(clm));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf', data, {responseType: 'blob'}).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'loan_info.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    },
    changeFilter(){
      let ss=[];
      if(this.department_id){
        ss = this.employees.map(item=> {
          if (item.department_id === this.department_id) return item.id
        }).filter(Boolean);
      }else{
        ss = this.employees.map(item=>item.id);
      }
      let ss2=[];
      if(this.designation_id){
        ss2 = this.employees.map(item=> {
          if (item.designation_id === this.designation_id) return item.id
        }).filter(Boolean);
      }else{
        ss2 = this.employees.map(item=>item.id);
      }
       this.filter_ids=ss.filter(function(n) { return ss2.indexOf(n) !== -1;});
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: 'light-success',
        0: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['employees','employeeNameById','employeeIdById', 'getEmployeeId',
      'versions', 'versionByID', 'shifts', 'shiftByID', 'departments', 'departmentByID',
      'designations', 'designationByID', 'designationScaleAmountByID', 'scales', 'scaleByID', 'designation_scales', 'designationScaleByID',
      'religions', 'genders', 'genderById', 'blood_groups', 'bloodGroupById', 'wings',
      'wingByID', 'marital_statuses', 'maritalStatusById', 'roles', 'roleByID', 'employeeRoles'
    ]),
    employee_loans(){
      return this.$store.getters.employee_loans.filter(item=>{
          if(this.filter_ids.includes(item.id)) return item;
        });
    },
    getData() {
      return this.employee_loans.map(item => ({
        EmployeeId: item.employee_id ? this.employeeIdById(item.employee_id) : '',
        Name: item.employee_id ? this.employeeNameById(item.employee_id) : '',
        LoanMonth: item.month_year ? item.month_year : '',
        Installment: item.issue_installment ? item.issue_installment : 0,
        PayableInstallment: item.payable_installment ? item.payable_installment : 0,
        LoanAmount: item.loan_amount ? item.loan_amount : 0,
        PayableAmount: item.payable_amount ? item.payable_amount : 0,
      }));
    },
  },
  created() {
    if (this.employee_loans.length < 1) this.$store.dispatch('GET_ALL_EMPLOYEE_LOAN');
    if (this.employees.length < 1) this.$store.dispatch('GET_ALL_EMPLOYEE');
    if (this.versions.length < 1) this.$store.dispatch('GET_ALL_VERSION');
    if (this.shifts.length < 1) this.$store.dispatch('GET_ALL_SHIFT');
    if (this.departments.length < 1) this.$store.dispatch('GET_ALL_DEPARTMENT');
    if (this.designations.length < 1) this.$store.dispatch('GET_ALL_DESIGNATION');
    if (this.scales.length < 1) this.$store.dispatch('GET_ALL_SCALE');
    if (this.designation_scales.length < 1) this.$store.dispatch('GET_ALL_DESIGNATION_SCALE');
    if (this.wings.length < 1) this.$store.dispatch('GET_ALL_WING');
    if (this.religions.length < 1) this.$store.dispatch('GET_ALL_RELIGION');
    if (this.blood_groups.length < 1) this.$store.dispatch('GET_ALL_BLOOD_GROUP');
    if (this.genders.length < 1) this.$store.dispatch('GET_ALL_GENDER');
    if (this.marital_statuses.length < 1) this.$store.dispatch('GET_ALL_MARITAL_STATUS');
    if (this.roles.length < 1) this.$store.dispatch('GET_ALL_ROLE');
    this.changeFilter();
  },
  watch:{
    department_id(){
      this.changeFilter();
    },
    designation_id(){
      this.changeFilter();
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>